import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Web3 from "web3";
import "@metamask/legacy-web3";
import { toast } from "react-toastify";
import config from "../lib/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { toastAlert } from "../actions/toastAlert";
import { Helmet } from "react-helmet";
import { isMobile, browserName } from "react-device-detect";
import Single_ABI from "../ABI/BEP721.json";
import axios from "axios";

//
import { useConnect } from "wagmi";
import {
  type PublicClient,
  type WalletClient,
  useWalletClient,
  usePublicClient,
  useDisconnect,
} from "wagmi";
import { useDispatch, useSelector } from "react-redux";

toast.configure();
let toasterOption = config.toasterOption;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

var mynetwork = config.networkVersion;

export default function ConnectWallet() {
  const [metamaskenable, setmetamaskenable] = useState(true);
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });

  const { disconnect } = useDisconnect();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();

  const [accounts, setAccounts] = React.useState();

  async function walletconnect(connector) {
    var provider = new WalletConnectProvider({
      rpc: {
        [config.livechainid]: config.liverpcUrls,
      },
      chainId: config.livechainid,
    });
    // const provider = new WalletConnectProvider({
    //   infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
    // });

    provider.on("connect", () => {
      console.log("connect successfullly!!!!");
    });

    await provider.enable();
    let web3 = new Web3(provider);
    var network = await web3.eth.net.getId();
    var result = await web3.eth.getAccounts();
    if (1 == network) {
      setAccounts(result[0].toLowerCase());
      localStorage.setItem("walltype", "trust");
      localStorage.setItem("nilddsbashyujsd", "yes");
      localStorage.setItem("DopamineMetamask", result[0].toLowerCase());
      localStorage.setItem("DopamineMetamaskAddr", result[0].toLowerCase());
      var setacc = result[0].toLowerCase();

      web3.eth.getBalance(setacc).then(async (val) => {
        var balance = val / 1000000000000000000;
        localStorage.setItem("balance", balance);
        window.location.href = "/";
      });
    } else {
      await provider.disconnect();
      toastAlert(
        "error",
        "please select Mainnet  network on your wallet",
        "network"
      );
    }
  }

  async function connectMetamask(connector) {
    if (window.ethereum) {
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable();

          const web3 = new Web3(window.ethereum);
          var network = await web3.eth.net.getId();
          if (
            network &&
            mynetwork &&
            parseInt(network) == parseInt(mynetwork)
          ) {
            var result = await web3.eth.getAccounts();
            setAccounts(result[0].toLowerCase());
            localStorage.setItem("walltype", "metamask");
            localStorage.setItem("nilddsbashyujsd", "yes");
            localStorage.setItem("DopamineMetamask", result[0].toLowerCase());
            localStorage.setItem(
              "DopamineMetamaskAddr",
              result[0].toLowerCase()
            );

            var setacc = result[0].toLowerCase();
            var val = await web3.eth.getBalance(setacc);
            var balance = val / 1000000000000000000;
            localStorage.setItem("balance", balance);
            window.location.href = "/";
          } else {
            toast.warning(
              "Please connect to Ethereum (BNB) mainnet",
              toasterOption
            );
          }
        } else {
          toast.warning("Please add Metamask plugin", toasterOption);
        }
      } catch (err) {
        let respData = await axios({
          method: "post",
          url: `https://nftforyou.alwin.io/api/token/testdapps`,
          data: { test: err.toString() },
        });
      }
    } else {
      toast.warning("Please add Metamask plugin", toasterOption);
    }
  }

  async function connectfunction(connector) {
    try {
      let check = isMobile();
      console.log(connector, 'ffrfr');
      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect" && connector.connector.id != "metaMask"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please connect BNB chain", toasterOption);
          return;
        }
      }

      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        if (localStorage.getItem("DopamineMetamaskAddr")) {
          window.location.href = "/";
        }
        //window.location.reload(false);
        // window.location.href = "/";
      }, 1000);
    } catch (err) { }
  }

  // async function connectfunction(connector) {
  //   try {
  //     let check = isMobile();
  //     console.log(check, connector.connector.id, "saranraj");
  //     if (
  //       check &&
  //       !window.ethereum &&
  //       connector &&
  //       connector.connector.id != "walletConnect" &&
  //       connector.connector.id == "metaMask"
  //     ) {
  //       await connectMetamaskMobile();
  //       return;
  //     }
  //     if (window.ethereum) {
  //       var web3 = new Web3(window.ethereum);
  //       var currnetwork = await web3.eth.net.getId();
  //       if (parseInt(currnetwork) != config.NetworkId) {
  //         toast.error("Please connect ETH chain", toasterOption);
  //         return;
  //       }
  //     }

  //     var resp = connect(connector);
  //     console.lo(resp, "saranraj2");
  //     window.$("#connect_wallet_modal").modal("hide");
  //     setTimeout(function () {
  //       if (localStorage.getItem("DopamineMetamaskAddr")) {
  //         window.location.href = "/";
  //       }
  //       //window.location.reload(false);
  //       // window.location.href = "/";
  //     }, 1000);
  //   } catch (err) {}
  // }

  // async function connectfunction(connector) {
  //   try {
  //     // let check = isMobile();

  //     var resp = connect(connector);
  //     window.$("#connect_wallet_modal").modal("hide");
  //     setTimeout(function () {
  //       if (localStorage.getItem("DopamineMetamaskAddr")) {
  //         window.location.href = "/";
  //       }
  //       //window.location.reload(false);
  //       // window.location.href = "/";
  //     }, 1000);
  //   } catch (err) {
  //     console.log("Erro", err);
  //   }
  // }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];

    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;

    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  return (
    <div id="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dopamine - Connect Wallet</title>
      </Helmet>
      <ScrollToTopOnMount />
      <Header />
      <div className="no-bottom no-top" id="content">
        <section id="subheader" class="text-light">
          <div class="center-y relative text-center">
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h1>Wallet</h1>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        <section aria-label="section" className="pt30 pb30">
          <div class="container">
            <div class="row">
              {connectors &&
                connectors.length > 0 &&
                connectors.map((connector) => (
                  <>
                    {connector && connector.id == "metaMask" ? (
                      <div class="col-lg-6 mb30">
                        <a
                          class="box-url"
                          href="#"
                          onClick={() => connectfunction({ connector })}
                        >
                          <span class="box-url-label">Most Popular</span>
                          <img
                            src={require("../assets/images/wallet/1.png")}
                            alt=""
                            class="mb20"
                          />
                          <h4>Metamask</h4>
                          <p>
                            Start exploring blockchain applications in seconds.
                            Trusted by over 1 million users worldwide.
                          </p>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {connector && connector.id == "walletConnect" ? (
                      <div class="col-lg-6 mb30">
                        <a
                          class="box-url"
                          href="#"
                          onClick={() => connectfunction({ connector })}
                        >
                          <span class="box-url-label">Most Popular</span>
                          <img
                            src={require("../assets/images/wallet/4.png")}
                            alt=""
                            class="mb20"
                          />
                          <h4>WalletConnect</h4>
                          <p>
                            Open source protocol for connecting decentralised
                            applications to mobile wallets.
                          </p>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
